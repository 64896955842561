// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context("../images", true)
const imagePath = (name) => images(name, true)

const fonts = require.context("../fonts", true)
const fontsPath = (name) => fonts(name, true)

import "stylesheets/application.scss";

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

Rails.start()
Turbolinks.start()

global.$ = require('jquery');
global.jQuery = $;
jQuery = $;
global.jquery_mask = require("jquery-mask-plugin/src/jquery.mask.js");

import "bootstrap-sass"; ///assets/javascripts/bootstrap.js";


$(document).on('turbolinks:load', function () {
  $('#subscription_number').mask(
    "HHHHHHHHHH", {
      placeholder: "740XXXXXX",
      translation: { 'H': { pattern: /[0-9]/}},
    }
  );
  $('#subscription_position_number').mask(
    "HHHHHH", {
      placeholder: "0010",
      translation: { 'H': { pattern: /[0-9]/} },
    }
  );
 $('#subscription_subscription_type_yearly_subscription').on('click', function() {
   if ($(this).is(':checked')) {
      $('#subscription_end_date_month option:selected').removeAttr('selected');
      $('#subscription_end_date_month option[value="12"]').attr('selected', true);
      let d = new Date();
      let nextYear = d.getYear() + 1;
      if (d.getMonth() == 11 && d.getDate() > 16) {
        $('#subscription_end_date_year option:selected').removeAttr('selected');
        $('#subscription_end_date_year option[value="' + nextYear + '"]').attr('selected', true);
      } else {
        $('#subscription_end_date_year option:selected').removeAttr('selected');
        $('#subscription_end_date_year option[value="' + d.getYear() + '"]').attr('selected', true);
      }
   }
 });
});
